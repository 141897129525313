/***
jQuery Multi Select
***/
.ms-container .ms-list {
  border: 1px solid #e5e5e5;
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;

}

.ms-container .ms-optgroup-label{
  font-size: 14px;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection{
  font-size: 13px;
}

.ms-container .ms-list.ms-focus {
  border-color: #999999;
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover{
  color: #333;
  background-color: #eee;
}

.ms-container .form-control {
  margin-bottom: 5px;
}