//## Dark blue theme color file

@import '../_variables';  // global metronic framework variables
@import '../components/_mixins'; // global metronic framework mixings

@import '_variables'; // theme level variables 

// Theme Colors Settigns

// Main settings
$theme-name:                                                    		"darkblue";  
$brand-main-color:                                               		lighten(#32c5d2, 1%) !default;      
$brand-main-font-color:													#ffffff !default;
  
// Body and header
$bg-color:                                                      		#364150 !default;
$header-bg-color:                                               		#2b3643 !default;  

// Mega Menu 
$header-hor-menu-bg-color:                                              $header-bg-color;     
$header-hor-menu-fixed-border-box:										0px 1px 10px 0px rgba($header-hor-menu-bg-color, 0.2);

//** Default Mega Menu
$header-hor-menu-mega-menu-header-font-color:                   		lighten($header-hor-menu-bg-color, 60%);

$header-hor-menu-default-link-font-color:                       		lighten($header-hor-menu-bg-color, 60%);
$header-hor-menu-default-link-font-color-on-hover:              		lighten($header-hor-menu-default-link-font-color, 5%);
$header-hor-menu-default-link-font-color-on-active:             		lighten($header-hor-menu-default-link-font-color, 30%);  

$header-hor-menu-default-link-icon-color:                       		lighten($header-hor-menu-bg-color, 35%);
$header-hor-menu-default-link-icon-color-on-hover:              		lighten($header-hor-menu-default-link-icon-color, 5%);
$header-hor-menu-default-link-icon-color-on-active:             		lighten($header-hor-menu-default-link-icon-color, 30%);

$header-hor-menu-default-link-bg-color-on-hover:                		lighten($header-bg-color, 10%); 
$header-hor-menu-default-link-bg-color-on-active:               		$brand-main-color;  

// Default Mega Menu Sub Menu 
$header-hor-menu-dropdown-menu-bg-color:                        		$header-hor-menu-default-link-bg-color-on-hover;    
$header-hor-menu-box-shadow:											5px 5px rgba($header-hor-menu-dropdown-menu-bg-color, 0.2);    

$header-hor-menu-dropdown-menu-link-bg-color-on-active:           		lighten($header-hor-menu-default-link-bg-color-on-hover, 4%); 
$header-hor-menu-dropdown-menu-link-bg-color-on-hover:           		lighten($header-hor-menu-default-link-bg-color-on-hover, 4%); 

$header-hor-menu-dropdown-menu-link-font-color:                 		lighten($header-hor-menu-bg-color, 60%);
$header-hor-menu-dropdown-menu-link-font-color-on-hover:        		lighten($header-hor-menu-dropdown-menu-link-font-color, 14%);         
$header-hor-menu-dropdown-menu-link-font-color-on-active:        		lighten($header-hor-menu-dropdown-menu-link-font-color, 14%);   

$header-hor-menu-dropdown-menu-link-icon-color:                 		lighten($header-hor-menu-dropdown-menu-link-font-color, 0%);
$header-hor-menu-dropdown-menu-link-icon-color-on-hover:        		lighten($header-hor-menu-dropdown-menu-link-icon-color, 14%);    
$header-hor-menu-dropdown-menu-link-icon-color-on-active:        		lighten($header-hor-menu-dropdown-menu-link-icon-color, 14%);     

//** Light Mega Menu
$header-light-hor-menu-mega-menu-header-font-color:                   	#666; 

$header-light-hor-menu-default-link-font-color:                       	$header-hor-menu-default-link-font-color; 
$header-light-hor-menu-default-link-font-color-on-hover:              	$header-hor-menu-default-link-font-color-on-hover;
$header-light-hor-menu-default-link-font-color-on-active:             	$header-hor-menu-default-link-font-color-on-active; 

$header-light-hor-menu-default-link-icon-color:                       	$header-hor-menu-default-link-icon-color;
$header-light-hor-menu-default-link-icon-color-on-hover:              	$header-hor-menu-default-link-icon-color-on-hover;
$header-light-hor-menu-default-link-icon-color-on-active:             	$header-hor-menu-default-link-icon-color-on-active;

$header-light-hor-menu-default-link-bg-color-on-hover:                	$header-hor-menu-default-link-bg-color-on-hover;
$header-light-hor-menu-default-link-bg-color-on-active:               	$header-hor-menu-default-link-bg-color-on-active;
 
$header-light-hor-menu-default-link-bg-color-on-onen:               	lighten(#FAFAFC, 2%);
$header-light-hor-menu-default-link-font-color-on-onen:               	#333; 
$header-light-hor-menu-default-link-icon-color-on-onen:               	#333;

// Light Mega Menu Sub Menu 
$header-light-hor-menu-dropdown-menu-bg-color:                        	$header-light-hor-menu-default-link-bg-color-on-onen;
$header-light-hor-menu-box-shadow:										5px 5px rgba(#666, 0.1);

$header-light-hor-menu-dropdown-menu-link-bg-color-on-active:           darken($header-light-hor-menu-dropdown-menu-bg-color, 4%); 
$header-light-hor-menu-dropdown-menu-link-bg-color-on-hover:           	darken($header-light-hor-menu-dropdown-menu-bg-color, 4%); 

$header-light-hor-menu-dropdown-menu-link-font-color:                 	#000;  
$header-light-hor-menu-dropdown-menu-link-font-color-on-hover:        	#000;  
$header-light-hor-menu-dropdown-menu-link-font-color-on-active:        	#000;  

$header-light-hor-menu-dropdown-menu-link-icon-color:                 	#888;
$header-light-hor-menu-dropdown-menu-link-icon-color-on-hover:        	#666;    
$header-light-hor-menu-dropdown-menu-link-icon-color-on-active:        	#666;  

// Header search
$header-search-bg-color:                                        		darken($header-bg-color, 4%) !default;
$header-search-bg-hover-color:                                  		$header-hor-menu-default-link-bg-color-on-hover !default;
$header-search-bg-color-on-open:                                		$header-search-bg-hover-color !default;
$header-search-font-color:                                      		#959fad !default;
$header-search-placeholder-font-color:                          		darken($header-search-font-color, 1%) !default;

// Top menu
$header-top-menu-bg-hover-color:                                		$header-hor-menu-default-link-bg-color-on-hover !default;
$header-top-menu-bg-hover-color-on-dropdown-open:               		$header-hor-menu-default-link-bg-color-on-hover !default;
$header-top-menu-badge-bg-color:                                		$brand-main-color;
$header-top-menu-badge-font-color:                              		#ffffff;
$header-top-menu-icon-font-color:                               		lighten(#606d80, 10%) !default;   

// Top menu user bar
$header-top-menu-user-font-color:                                       $header-hor-menu-default-link-font-color !default; 
$header-top-menu-user-bg-color:                                 		$header-search-bg-color !default;

//begin: Top Menu Extended Dropdowns
$header-top-menu-extended-dropdown-item-bg-color-on-hover:              #f8f9fa;
$header-top-menu-extended-dropdown-item-border-color:                   #EFF2F6;
$header-top-menu-extended-dropdown-item-font-color:						#888888;

$header-top-menu-extended-dropdown-header-bg-color:						darken(#f7f8fa, 4%);
$header-top-menu-extended-dropdown-border-color:                        darken($header-top-menu-extended-dropdown-header-bg-color, 1%);
$header-top-menu-extended-dropdown-header-font-color:					darken(#6f949c, 5%); 

$header-top-menu-inbox-dropdown-from-font-color:						#5b9bd1;  

$header-top-menu-task-dropdown-progress-bg-color:						#dfe2e9;

$header-top-menu-notification-time-bg-color:							lighten(#eee, 1%);

// Top Menu Notifications
$header-top-menu-dropdown-dark-bg-color: 								lighten($header-hor-menu-bg-color, 10%);
$header-top-menu-dropdown-dark-header-bg-color:							lighten($header-hor-menu-bg-color, 2%);
$header-top-menu-dropdown-dark-header-font-color:						lighten($header-top-menu-dropdown-dark-header-bg-color, 50%);

$header-top-menu-dropdown-dark-item-font-color:							lighten($header-top-menu-dropdown-dark-header-bg-color, 55%);  
$header-top-menu-dropdown-dark-item-border-color:						lighten($header-top-menu-dropdown-dark-bg-color, 6%);
$header-top-menu-dropdown-dark-item-icon-color:							lighten($header-top-menu-dropdown-dark-header-bg-color, 45%);
$header-top-menu-dropdown-dark-item-bg-color-on-hover:					lighten($header-top-menu-dropdown-dark-bg-color, 4%);

$header-top-menu-dropdown-dark-default-menu-divider:					$header-top-menu-dropdown-dark-item-border-color;
$header-top-menu-dropdown-dark-notification-time-bg-color:				darken($header-top-menu-dropdown-dark-bg-color, 5%);
//end: Top Menu Extended Dropdowns

// Sidebar menu
$sidebar-menu-devider-border-color:                             		darken(#3f4b5a, 1%) !default;

$sidebar-menu-link-font-color:                                  		#b4bcc8 !default;
$sidebar-menu-link-font-color-on-hover:                         		$sidebar-menu-link-font-color !default;
$sidebar-menu-link-font-color-on-active:                        		#ffffff !default;

$sidebar-menu-link-icon-font-color:                             		#606C7D !default;
$sidebar-menu-link-icon-font-color-on-active:                   		$sidebar-menu-link-font-color-on-active !default;
$sidebar-menu-link-icon-font-color-on-hover:                            $sidebar-menu-link-icon-font-color !default; 

$sidebar-menu-link-bg-color-on-hover:                           		#2C3542 !default;
$sidebar-menu-link-bg-color-on-active:                          		$brand-main-color;  

$sidebar-menu-arrow-color:                                      		$sidebar-menu-link-icon-font-color !default;
$sidebar-menu-arrow-color-on-active:                            		$sidebar-menu-link-font-color-on-active !default;
$sidebar-menu-arrow-color-on-hover:                                     $sidebar-menu-link-icon-font-color-on-hover !default;

$sidebar-menu-sub-menu-box-shadow-color:                                $sidebar-menu-link-bg-color-on-hover !default;
$sidebar-menu-sub-menu-link-font-color:                         		#b4bcc8 !default; 
$sidebar-menu-sub-menu-link-icon-font-color:                    		$sidebar-menu-arrow-color !default;
$sidebar-menu-sub-menu-link-bg-color-on-hover:                  		lighten($bg-color, 4%) !default;
$sidebar-menu-sub-menu-link-icon-font-color-on-hover:                   $sidebar-menu-link-icon-font-color-on-hover !default;

$sidebar-menu-hover-sub-menu-bg-color:									lighten($sidebar-menu-link-bg-color-on-hover, 3%) !default;

// Sidebar for mobile
$mobile-sidebar-menu-bg-color:                           				darken($bg-color, 7%) !default;   
$mobile-sidebar-menu-link-bg-color-on-hover:             				darken($bg-color, 4%) !default;   

// Light sidebar menu
$light-sidebar-menu-link-border-color:                                  $brand-main-color !default; 
$light-sidebar-menu-link-bg-color-on-hover:                             lighten($bg-color, 2%) !default; 
$light-sidebar-menu-link-bg-color-on-active:                            lighten($bg-color, 4%) !default;
$light-sidebar-menu-link-font-color-on-active:                          #f1f1f1 !default;  
$light-sidebar-menu-link-icon-color-on-active:                          #eeeeee !default;         
$light-sidebar-menu-link-arrow-color-on-active:                         #eeeeee !default;      

$light-sidebar-menu-sub-menu-bg-color:                                  lighten($bg-color, 2%) !default;
$light-sidebar-menu-sub-menu-link-bg-color-on-hover:					lighten($bg-color, 4%) !default; 

$mobile-light-sidebar-menu-sub-menu-bg-color:                           $mobile-sidebar-menu-bg-color !default;   
$mobile-light-sidebar-menu-link-bg-color-on-hover:             			lighten($mobile-sidebar-menu-bg-color, 3%) !default;
$mobile-light-sidebar-menu-link-bg-color-on-active:             		lighten($mobile-sidebar-menu-bg-color, 3%) !default;
$mobile-light-sidebar-menu-sub-menu-link-bg-color-on-hover:             lighten($mobile-sidebar-menu-bg-color, 3%) !default;

// Sidebar search
$sidebar-search-bg-color:                                               darken($bg-color, 5%) !default;
$sidebar-search-bottom-border-color:                            		lighten($sidebar-menu-devider-border-color, 3%) !default;   
$sidebar-search-full-border-color:                              		lighten($sidebar-menu-devider-border-color, 3%) !default;     
$sidebar-search-input-font-color:                               		lighten($sidebar-menu-devider-border-color, 8%) !default;
$sidebar-search-input-placeholder-font-color:                   		lighten($sidebar-menu-devider-border-color, 8%) !default;

// Footer
$footer-default-font-color:                                     		lighten($bg-color, 40%) !default;     
$footer-default-go-top-bg-color:                                		lighten($bg-color, 7%) !default;       
$footer-default-go-top-icon-font-color:                         		lighten($bg-color, 30%) !default;
$footer-fixed-bg-color: 												darken($bg-color, 7%) !default; 

//Boxed layout
$for-min-992px-page-boxed-page-container-bg-color:              		$sidebar-menu-devider-border-color !default;      
$for-min-992px-page-boxed-bg-color:                             		darken($bg-color, 3%) !default;
$for-min-992px-page-boxed-sidebar-fixed-border-color:           		$sidebar-menu-devider-border-color !default;

@import '_base';
