@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "lookbooks/lookbooks";

/*
* Theme custom
*/

.page-quick-sidebar-open {
  .page-quick-sidebar-toggler {
    > i {
      &:before {
        content: "\f08b" !important;
      }
      font-family: 'FontAwesome';
    }
  }
}

.page-header {
  .top-menu {
    .dropdown-quick-sidebar-toggler {
      > .dropdown-toggle {
        i {
          &:before {
            content: "\f090" !important;
          }
          font-family: 'FontAwesome';
        }
      }
    }
  }
  &.navbar {
    .page-logo {
      padding-left: 15px;
      padding-right: 15px;

      .logo-default {
        margin: 9px 0 0 0 !important;
      }
    }
  }
}

.page-quick-sidebar-wrapper {
  padding: 35px 10px 0px 10px;
}

.page-footer {
  .page-footer-inner span {
    color: #eb5a4e;
    font-weight: 200;
  }
}

form {
  &.form-inline {
    display: inline-block;
  }

  .control-label .required, .form-group .required {
    color: inherit !important;
    font-size: 14px !important;

    &:after {
      content: "*";
      color: #e02222;
      margin-left: 5px;
    }
  }
}

/*
* DataTables custom
*/

.dataTables_wrapper {

  .table-group-actions {
    text-align: right;
  }

  .dataTables_processing {
    padding: 10px !important;
    height: auto !important;
  }

  div.dataTables_filter {
    input {
      margin-left: 10px;
    }
  }


  table {
    tbody, thead {
      tr {
        td:last-child {
          text-align: right;
        }
      }
    }
  }
}


.form-break {
  clear: both;
}
input[type="file"], .input-group {
  margin-bottom:15px;
}

.languages {
  float:left;
}

.mce-tinymce {
  margin-bottom:20px !important;
}

.float-none {
  float:none !important;
}
