@import "layouts/layout1";
/**
 * BEGIN: Lookbook
 */
.lookbook {
  position:relative;

  .popover {
    min-width: 250px;
  }

  @include mq($from: mobile) {
    height: 1563px;
  }

  @include mq($from: tablet) {
    height: 925px;
  }

  @include mq($from: desktop) {
    height:490px;
  }

  .block {

    .delete-product {
      position: absolute;
      bottom: 0px;
      right: 0px;
    }

    .circle {
      position: absolute;
      top: 0%;
      left: 0%;
      height: 27px;
      width: 27px;
      line-height: 25px;
      background-color: #fff;
      border-radius: 50%;
      text-align: center;

      &:before {
        content: "+";
        font-size: 16px;
        font-weight: bold;
      }

      &:hover {
        .hover {
          display: block;
        }
      }

      .hover {
        display: none;
        position: absolute;
        bottom: 32px;
        background: white;
        z-index: 10;
        left: -66px;
        padding: 9px 15px;
        text-align: left;
        width: 152px;
        line-height: 20px;

        .product-name {
          text-transform: uppercase;
        }

        .brand-name {
          color: #aaa;
          font-size: 12px;
        }
      }
    }
  }
}
